import Link from "next/link"
import React, { useEffect, useState } from "react"
import InstagramSvg from "../icon/instagram"
import LinkedinSvg from "../icon/linkedin"
import XingSvg from "../icon/xing"
import LinkButton from "../ui/LinkButton"
import styles from "./Footer.module.scss"
import LogoStacked from "./LogoStacked"
import cn from "classnames"
import Logo from "components/Header/Logo"

const Footer = ({
  pageId,
  footerNav = { items: [] },
  footerLinks = { items: [] },
  company = {},
}) => {
  const [filteredFooterLinks, setFilteredFooterLinks] = useState(null)

  useEffect(() => {
    if (pageId && footerLinks && footerLinks.items?.length > 0) {
      const links = footerLinks.items.filter((link) => {
        if (
          link.published &&
          link.footer_link_pages.filter((p) => p.id === pageId).length > 0
        ) {
          return true
        }
      })
      setFilteredFooterLinks(links)
    }
  }, [pageId, footerLinks])

  const handleScrollUp = () => {
    // Respect prefers-reduced-motion preference
    const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    window.scrollTo({ 
      left: 0, 
      top: 0, 
      behavior: prefersReducedMotion ? 'auto' : 'smooth' 
    })
  }

  return (
    <footer className={`footer ${styles.footer}`}>
      <div className={styles.inner}>
        <nav className={styles.topLinks} aria-label={"Footer Links"}>
          <ul>
            {filteredFooterLinks &&
              filteredFooterLinks.map((navItem) => (
                <li key={navItem.id}>
                  <Link href={navItem.url} prefetch={false}>
                    <a>{navItem.title}</a>
                  </Link>
                </li>
              ))}
          </ul>
        </nav>

        <div className={styles.contact}>
          <LinkButton
            className={cn("button--inverted", styles.contactButton)}
            data={{ link_url: "/kontakt", link_text: "Kontakt" }}
          />
        </div>

        <div className={styles.bottomLinks}>
          <div className={styles.navs}>
            <nav className={styles.social} aria-label={"Socialmedia Links"}>
              <Link href="https://www.instagram.com/disphere.interactive/">
                <a
                  aria-label="disphere interactive GmbH instagram Profil"
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                >
                  <InstagramSvg />
                </a>
              </Link>
              <Link href="https://www.linkedin.com/company/disphere-interactive">
                <a
                  aria-label="disphere interactive GmbH linkedin Profil"
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                >
                  <LinkedinSvg />
                </a>
              </Link>
            </nav>
            <nav className={styles.legal} aria-label={"Weitere Links"}>
              {footerNav.items.map((navItem) => (
                <Link key={navItem.id} href={navItem.url}>
                  <a>{navItem.title}</a>
                </Link>
              ))}
              <a
                tabIndex={0}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window?.Cookiebot?.show()
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    window?.Cookiebot?.show()
                  }
                }}
              >
                Datenschutzeinstellungen
              </a>
            </nav>
          </div>
          <div dangerouslySetInnerHTML={{ __html: company.mission }}></div>
        </div>

        <div className={styles.logos}>
          <Logo />
        </div>
      </div>
      <div className="flex justify-center mt-24">
        <button onClick={handleScrollUp} className={cn("button", styles.btt)}>
          <span className={styles.buttonText}>
            Nach oben
            <svg
              width="30"
              height="30"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.14645 2.14645C7.34171 1.95118 7.65829 1.95118 7.85355 2.14645L11.8536 6.14645C12.0488 6.34171 12.0488 6.65829 11.8536 6.85355C11.6583 7.04882 11.3417 7.04882 11.1464 6.85355L8 3.70711L8 12.5C8 12.7761 7.77614 13 7.5 13C7.22386 13 7 12.7761 7 12.5L7 3.70711L3.85355 6.85355C3.65829 7.04882 3.34171 7.04882 3.14645 6.85355C2.95118 6.65829 2.95118 6.34171 3.14645 6.14645L7.14645 2.14645Z"
                fill="currentColor"
              ></path>
            </svg>
          </span>
        </button>
      </div>
    </footer>
  )
}

export default Footer
